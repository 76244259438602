@font-face {
  font-family: 'Garnet Capitals';
  src: url('../fonts/GarnetCapitals-Thin.eot');
  src: local('Garnet Capitals Thin'), local('/fonts/GarnetCapitals-Thin'),
  url('../fonts/GarnetCapitals-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GarnetCapitals-Thin.woff2') format('woff2'),
  url('../fonts/GarnetCapitals-Thin.woff') format('woff'),
  url('../fonts/GarnetCapitals-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Garnet Capitals';
  src: url('../fonts/GarnetCapitals-Regular.eot');
  src: local('Garnet Capitals Regular'), local('GarnetCapitals-Regular'),
  url('../fonts/GarnetCapitals-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GarnetCapitals-Regular.woff2') format('woff2'),
  url('../fonts/GarnetCapitals-Regular.woff') format('woff'),
  url('../fonts/GarnetCapitals-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Garnet Capitals Inline';
  src: url('../fonts/GarnetCapitals-Inline.eot');
  src: local('Garnet Capitals Inline'), local('GarnetCapitals-Inline'),
  url('../fonts/GarnetCapitals-Inline.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GarnetCapitals-Inline.woff2') format('woff2'),
  url('../fonts/GarnetCapitals-Inline.woff') format('woff'),
  url('../fonts/GarnetCapitals-Inline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Garnet Capitals';
  src: url('../fonts/GarnetCapitals-Bold.eot');
  src: local('Garnet Capitals Bold'), local('GarnetCapitals-Bold'),
  url('../fonts/GarnetCapitals-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GarnetCapitals-Bold.woff2') format('woff2'),
  url('../fonts/GarnetCapitals-Bold.woff') format('woff'),
  url('../fonts/GarnetCapitals-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Garnet Capitals';
  src: url('../fonts/GarnetCapitals-Black.eot');
  src: local('Garnet Capitals Black'), local('GarnetCapitals-Black'),
  url('../fonts/GarnetCapitals-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GarnetCapitals-Black.woff2') format('woff2'),
  url('../fonts/GarnetCapitals-Black.woff') format('woff'),
  url('../fonts/GarnetCapitals-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Garnet Script';
  src: url('../fonts/GarnetScript-Regular.eot');
  src: local('Garnet Script Regular'), local('GarnetScript-Regular'),
  url('../fonts/GarnetScript-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GarnetScript-Regular.woff2') format('woff2'),
  url('../fonts/GarnetScript-Regular.woff') format('woff'),
  url('../fonts/GarnetScript-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Garnet Capitals';
  src: url('../fonts/GarnetCapitals-Light.eot');
  src: local('Garnet Capitals Light'), local('GarnetCapitals-Light'),
  url('../fonts/GarnetCapitals-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GarnetCapitals-Light.woff2') format('woff2'),
  url('../fonts/GarnetCapitals-Light.woff') format('woff'),
  url('../fonts/GarnetCapitals-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

