.games-page {
  .user-type {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    opacity: 0.4;
  }

  .user-info {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding: 12px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .user-score {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
  }

  .user-score__label {
    color: #000;
  }

  .user-score__amount {
    margin-left: 12px;
    color: #D33286
  }

  .games {
    display: flex;
    flex-wrap: wrap;
    max-width: 336px;
    gap: 15px;
    margin: 0 auto;
  }

  .games-loading {
    min-height: 480px;
  }

  .game {
    position: relative;
    width: 160px;
    height: 150px;
    background-color: darkcyan;

    &:after {
      position: absolute;
      content: '';
      top: 2px;
      left: 2px;
      width: 100%;
      height: 100%;
      border: 1px solid #D33286;
      z-index: -1;
    }
  }
}

.game-page {
  max-height: 100vh;
  overflow: hidden;

  .go-back {
    padding: 20px;
  }

  .game-iframe {
    width: 100%;
    height: calc(100vh - 64px);
    flex: 1;
    border: none;
  }
}

.games-page {
  padding-bottom: 260px;
}