.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: transparentize(#000, 0.64);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  width: 100%;
  max-width: 480px;
  min-width: 320px;
  margin: 32px;
  background-color: #fff;
  padding: 40px 20px;
  transition: transform 0.3s;

  &:after {
    position: absolute;
    content: '';
    height: 116px;
    background-image: url("../images/plus-bg.svg");
    background-repeat: repeat;
    background-position: center;
    bottom: -60px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &.change-position {
    transform: translateY(-40px);
  }
}

.basic-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .subtitle {
    margin-top: 12px;
  }

  .title {
    margin-top: 4px;
  }

  input {
    margin-top: 32px;
  }

  button {
    margin-top: 16px;
  }

  .basic-modal__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
  }
}