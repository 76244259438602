* {
  box-sizing: border-box;
}

.container {
  max-width: 480px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  max-width: 316px;
}

.logo-link {
  display: flex;
  justify-content: center;
}

.page {
  padding: 20px 0;
}

body {
  min-width: 320px;
  margin: 0;
  font-family: 'Garnet Capitals', Arial, sans-serif;
}

.description {
  position: relative;
  margin: 10px -20px;
  text-align: center;
}

.title {
  font-size: 32px;
  color: #D33286;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.16em;
}

.subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.16em;
}

.plus-bg {
  position: absolute;
  top: 6px;
  left: 26px;
  width: calc(100% - 26px);
  height: 116px;
  background-image: url("../images/plus-bg.svg");
  background-repeat: repeat;
}

.go-back {
  display: flex;
  align-items: center;
  min-height: 32px;
  border: none;
  background-color: transparent;
  font-family: inherit;
  color: #000;
  text-decoration: none;

  .subtitle {
    margin-left: 2px;
  }
}

input {
  width: 100%;
  height: 52px;
  padding: 0 16px;
  border: 1.4px solid #D33286;
  font-family: inherit;
}

::placeholder {
  font-size: 16px;
  color: transparentize(#000, 0.4);
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px;
  border: none;
  font-size: 16px;
  line-height: 20px;
  font-family: inherit;
  color: #fff;

  svg {
    margin-right: 8px
  }
}

.btn.pink {
  background-color: #D33286;

  &.disabled {
    position: relative;
    z-index: 1;
    background: linear-gradient(0deg, rgba(211, 50, 134, 0.3), rgba(211, 50, 134, 0.3)), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  }
}

.btn.black {
  background-color: #000;
}


// transition

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 666;
  background-color: #fff;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #D33286;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}