.leaderboard-page {
  .plus-bg {
    top: -40px
  }

  .preview-image {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 160px;
    margin-top: 10px;
    background-size: cover;
    background-position: center;
    background-color: darkcyan;
  }

  .score-list {
    margin-top: 40px;
  }

  .score-item {
    display: flex;
    align-items: center;
    min-height: 36px;
    margin-top: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  .score-item__name {
    max-width: 44%;
    margin-right: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #000;
  }

  .score-item__date {
    min-width: 100px;
    margin-left: auto;
    margin-right: 20px;

    + .score-item__amount {
      margin-left: 0;
    }
  }

  .score-item__amount {
    min-width: 40px;
    text-align: right;
    margin-left: auto;
    color: #D33286;
  }

  .your-result {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 120px;
    background-color: #fff;

    .score-item {
      border-bottom: 1px solid #000;
    }
  }

  .your-result__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.16em;
    text-align: center;
    color: #D33286;
  }
}